// Some common variables and mixins to get you started.
// Variables allow you to re-use colors, sizes, and other values without repeating
// yourself. This means that changes that should be small, such as tweaking the
// coloring or the sizing, can be done in one place, not all over the stylesheet.

//$base-font: 		"Source Sans Pro", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$base-font: 		"Arimo", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$header-font: 		$base-font;
$ms-base:           1em, .875em;
$ms-ratio:          1.618;


// base color scheme
$body-background: 	#ffffff;
$color_body: 		#43454b;
$color_links: 		#2c2d33;
$color_border:		rgba(0,0,0,0.05);
$color_woocommerce: #96588a;
$error: 			#e2401c;
$success: 			#0f834d;
$info: 				#3D9CD2;

$primary-color: #ae9a86; 
$primary-color-dark: darken($primary-color, 10%);
$primary-color-light: #e0dacd; 

$secondary-color: #bed031;
$secondary-color-dark: darken($secondary-color, 10%);
$secondary-color-light: lighten($secondary-color, 10%);

$grey: #666; 

$dark: #000;
$light: #fff;

// layout sizes
$desktop:			768px;
$handheld:			568px;
$container-width:	67.141em;

$susy: (
	columns: 12,
	gutters: 1,
	math: fluid,
	output: float,
	gutter-position: after,

	use-custom: (
		box-sizing: false
	)
);