@mixin clearfix {
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin hoverActiveFocus() {
    &:hover, &:active, &:focus {
        @content;
    }
}

@mixin screen-reader-text() {
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
}

@mixin underlinedLink() {
	font-weight: 400;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

@mixin message($glyph: "\f05a", $color: $info)
{
    padding: 1em ms(3) 1em ms(6);
    background: rgba( $color, .7 );
    margin-bottom: ms(5);
    font-weight: normal;
    position: relative;
    color: #fff;
    text-shadow: none;

    &:before {
        font-family: 'FontAwesome';
        content: $glyph;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: ms(5);
        background: rgba( darken( $color, 3% ), .7 );
        text-align: center;
        padding: 1em ms(-2);
        font-weight: normal !important;
        text-shadow: none;
    }

    a {
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }

    a.button {
        background: #fff;
        color: $color;
        font-size: ms(-1);
        padding: .202em ms(-2);

        &:hover,
        &:active {
            background: rgba(#fff,.8);
            color: $color;
        }
    }
}

@mixin button() {
	border: 0;
	background: none;
	background-color: $color_body;
	border-color: $color_body;
	color: #fff;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	padding: ms(-2) ms(2);
	text-decoration: none;
	font-weight: 600;
	text-shadow: none;
	display: inline-block;
	outline: none;
	-webkit-appearance: none;
	-webkit-font-smoothing: antialiased;
	border-radius: 0;
	box-shadow:
		inset 0 -1px 0 rgba(#000,.3);
}