// Bourbon
// See: http://bourbon.io/docs/
@import 'bourbon'; 

// Susy
// Susy grid system. See: http://susydocs.oddbird.net/en/latest/
@import 'node_modules/susy/sass/susy';

// Utilities 
// Sass tools and helpers used across the project.
@import 'utils/variables';
@import 'utils/mixins';

// Vendors
// External libraries and frameworks.
@import 'vendors/normalize';
@import 'vendors/font-awesome/font-awesome'; 
@import 'vendors/modular-scale';

// Base
// Includes all the main Storefront css.
@import 'base/base';
@import 'base/layout';

@import 'woocommerce/woocommerce';
