// Outputs a list of values instead of a single value
@function ms-list($Start: 0, $End: 0, $Bases: $ms-base, $Ratios: $ms-ratio) {
  
  // Seed results
  $Positive-return: ();
  $Negitive-return: ();
  $Return: ();

  @if $End >= 0 {
    // Generate a list of all possible values
    $Positive-return: ms-generate-list($End, $Bases, $Ratios);
    
    // Sort the generated lists
    $Positive-return: ms-sort-list($Positive-return);

    // Trim list
    $Trim-list: ();
    // If the starting value is a positive number
    // trim the positive return from that
    @if $Start >= 0 {
      @for $i from ($Start + 1) through $End + 1 {
        $Trim-list: join($Trim-list, nth($Positive-return, $i));
      }
    }
    // If not, then include everything up to the end.
    @else {
      @for $i from 1 through $End + 1 {
        $Trim-list: join($Trim-list, nth($Positive-return, $i));
      }
    }
    $Positive-return: $Trim-list;
  }
  
  // Generate a negitive list
  @if $Start < 0 {
    // Generate a list of all possible values
    $Negitive-return: ms-generate-list($Start, $Bases, $Ratios);
    
    // Sort the generated lists
    $Negitive-return: ms-sort-list($Negitive-return);
    
    // Reverse negitive list results.
    $MS-new-return: ();
    @each $i in $Negitive-return {
      $MS-new-return: join($i, $MS-new-return);
    }
    $Negitive-return: $MS-new-return;

    // Trim list
    $Trim-list: ();
    @if $End < 0 {
      @for $i from abs($End) through (abs($Start) + 2) {
        $Trim-list: join(nth($Negitive-return, $i), $Trim-list);
      }
    }
    @else {
      @for $i from 2 through (abs($Start) + 1) {
        $Trim-list: join(nth($Negitive-return, $i), $Trim-list);
      }
    }
    $Negitive-return: $Trim-list;
  }
  
  // Join both positive and negitive possibilities.
  $Return: join($Negitive-return, $Positive-return);

  @return $Return;
}
