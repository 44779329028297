// Feature testing


// Test if the pow() function exists
@function ms-pow-exists() {
  @if pow(4, 2) == 16 {
    @return true;
  }
  @return false;
}

$MS-pow-exists: ms-pow-exists();

// Test if MS was installed via the gem
@function ms-gem-exists() {
  @if ms-gem-installed() == true {
    @return true;
  }
  @return false;
}

$MS-gem-exists: ms-gem-exists();