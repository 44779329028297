// The main function that brings it all together
@function ms($Value: 0, $Bases: $ms-base, $Ratios: $ms-ratio) {

  // If no multi-base or multi-ratio stuff is going on
  // then just retrn the basic calculaiton
  @if length($Bases) == 1 and length($Ratios) == 1 {
    @return ms-round-px(ms-calc($Value, $Bases, $Ratios));
  }

  // Do calculations directly in Ruby when avalible
  @if $MS-gem-exists {

    // Remove units from bases
    $Unit: nth($Bases, 1) * 0 + 1; // Extracts the unit from the base
    $Unitless-Bases: ();
    @each $Base in $Bases {
      $Base: $Base/$Unit;
      $Unitless-Bases: join($Unitless-Bases, $Base);
    }

    // Calculate natively in Ruby
    @return ms-round-px(ms-gem-func($Value, $Unitless-Bases, $Ratios) * $Unit);
  }

  // Generate a list of all possible values
  $Return: ms-generate-list($Value, $Bases, $Ratios);

  // Sort the generated lists
  $Return: ms-sort-list($Return);

  // Reverse list if its negitive.
  @if $Value < 0 {
    $MS-new-return: ();
    @each $i in $Return {
      $MS-new-return: join($i, $MS-new-return);
    }
    $Return: $MS-new-return;
  }

  // Normalize value for counting from 1
  // Because CSS counts things from 1
  // So Sass does as well
  // So I get to write fun stuff like this
  $Value: abs($Value) + 1;

  // Find the correct value in the list
  $Return: nth($Return, $Value);

  @return ms-round-px($Return);
}

// Same function, different name, for good measure.
@function modular-scale($Value: 0, $Bases: $ms-base, $Ratios: $ms-ratio) {
  @return ms($Value, $Bases, $Ratios);
}